import IconWhatsApp from "../assets/IconWhatsapp.svg";
import LogoSelsyn from "../assets/LogoSelsyn.svg";
import IconInsta from "../assets/IconInsta.svg";
import Select from "./Select";
import { Link, useLocation } from "react-router-dom";
import Dropdown from "./Dropdown";

export default function Nav() {
  const location = useLocation();

  const handleAboutUsClick = (e) => {
    if (location.pathname === "/") {
      e.preventDefault();
      const element = document.getElementById("about-us");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  return (
    <>
      <nav className="flex items-center justify-around bg-white  w-full">
        <div className="flex-shrink-0">
          <Link to="/">
            <img src={LogoSelsyn} alt="Logo" />
          </Link>
        </div>
        <div>
          <ul className="hidden md:flex space-x-5 text-black font-bold text-3x1">
            <li className="flex items-center">
              <Select
                title="Soluções"
                item="Plataforma"
                link="/Plataforma"
                item2="S-Pro"
                link2="/Spro"
              />
            </li>
            <li className="flex items-center">
              <Select
                title="Conteudo"
                item="Mentoria"
                link="/Mentoria"
                item2={null}    
              />
            </li>
            <li className="flex items-center">
              <Link to="/#about-us" onClick={handleAboutUsClick}>
                Sobre nós
              </Link>
            </li>
            <li className="flex items-center">
              <Link to="/Contato">Contato</Link>
            </li>
            <div className="flex">
              <li className="flex items-center">
                <a
                  target="_blank"
                  href="https://api.whatsapp.com/send?phone=5548991040129&text=Ol%C3%A1,%20vim%20atrav%C3%A9s%20do%20site,%20gostaria%20de%20conhecer%20mais%20sobre%20a%20Plataforma."
                >
                  <img src={IconWhatsApp} alt="WhatsApp" />
                </a>
              </li>
              <li className="flex items-center">
                <a
                  target="_blank"
                  href="https://www.instagram.com/selsyntecnologia/"
                >
                  <img src={IconInsta} alt="Instagram" />
                </a>
              </li>
            </div>
          </ul>
          <div className="z-10 block md:hidden">
            <Dropdown />
          </div>
        </div>
      </nav>
    </>
  );
}
