import { useEffect, useState } from "react";

import "./Home.css";
import MacBook from "../assets/macBook.svg";
import DeskTopDash from "../assets/DeskTopDash.svg";
import Mandala from "../assets/Mandala.svg";
import Equipe from "../assets/SELSYN-Equipe.svg";
import Slogan from "../assets/Slogan.svg";
import Ping from "../assets/pingMapa.svg";
import Custo from "../assets/Custo.svg";
import White from "../assets/White.svg";
import Ranking from "../assets/Ranking.svg";
import sulvias from "../assets/Sulvias1.svg";
import Look from "../assets/LookDrive2.svg";
import Vieira from "../assets/Vieria3.svg";
import autoRastro from "../assets/autoRastro.svg";
import Google5 from "../assets/Google5.svg";
import Google6 from "../assets/Google6.svg";
import NossoAplicativo from "../assets/CELULARES.svg";

export default function Home() {
  const [isVisible, setIsVisible] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [isDragging, setIsDragging] = useState(false);

  const Central = () => {
    return <ins className="text-gradientCentral font-bold">central de rastreamento</ins>;
  };

  const LinhaParceiro = () => {
    return (
      <ins className="text-Azul font-bold ">Somos seu parceiro de negócios</ins>
    );
  };

  const handleScroll = () => {
    const containerIde = document.querySelector(".container-Ide");
    if (containerIde) {
      const rect = containerIde.getBoundingClientRect();

      const visibleAmount = window.innerHeight * 0.5;
      if (
        rect.top <= window.innerHeight - visibleAmount &&
        rect.bottom >= visibleAmount
      ) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    }
  };

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.substring(1);
      if (hash) {
        const element = document.getElementById(hash);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }
    };

    handleHashChange();
    window.addEventListener("hashchange", handleHashChange);

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("hashchange", handleHashChange);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleMouseDown = (event) => {
    if (event.button === 0) {
      setIsDragging(true);
      setStartX(event.pageX - event.currentTarget.offsetLeft);
      setScrollLeft(event.currentTarget.scrollLeft);
      event.preventDefault();
    }
  };

  const handleMouseMove = (event) => {
    if (!isDragging) return;

    const x = event.pageX - event.currentTarget.offsetLeft;
    const walk = (x - startX) * 2;

    const containerWidth = event.currentTarget.scrollWidth;
    const maxScrollLeft = containerWidth - event.currentTarget.clientWidth;
    const newScrollLeft = Math.min(
      Math.max(scrollLeft - walk, 0),
      maxScrollLeft
    );

    if (event.buttons === 1) {
      event.currentTarget.scrollLeft = newScrollLeft;
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
  };

  const handleTouchStart = (event) => {
    setIsDragging(true);
    setStartX(event.touches[0].pageX - event.currentTarget.offsetLeft);
    setScrollLeft(event.currentTarget.scrollLeft);
  };

  const handleTouchMove = (event) => {
    if (!isDragging) return;

    const x = event.touches[0].pageX - event.currentTarget.offsetLeft;
    const walk = (x - startX) * 2;

    const containerWidth = event.currentTarget.scrollWidth;
    const maxScrollLeft = containerWidth - event.currentTarget.clientWidth;
    const newScrollLeft = Math.min(
      Math.max(scrollLeft - walk, 0),
      maxScrollLeft
    );

    event.currentTarget.scrollLeft = newScrollLeft;
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
  };
  return (
    <>
      <div className=" flex mx-0 z-0 my-10  h-5/12 md:z-0 md:mt-0  md:flex  items-center justify-center md:h-screen md:mx-20">
        <div className=" md:ml-24 p-6">
          <h3 className="text-center font-semibold text-lg md:font-semibold md:text-left md:text-2xl mb-3">
            Monte sua &nbsp;
            <Central />
            &nbsp; com
          </h3>
          <h1 className="font-bold text-center text-gradient text-3xl md:font-bold md:text-left md:text-5xl  text-gradient">
            A PLATAFORMA MAIS COMPLETA DO MERCADO
          </h1>
          <div className="flex justify-center md:justify-start">
            <a
              target="_blank"
              href="https://api.whatsapp.com/send?phone=5548991040129&text=Ol%C3%A1,%20vim%20atrav%C3%A9s%20do%20site.%20Quero%20montar%20minha%20central%20e%20conhecer%20mais%20a%20plataforma."
            >
              <button className=" text-gradientButton  md:text-gradientButton font-bold mt-6 px-8 py-4 rounded-full hover:scale-110  duration-500">
                Montar minha central
              </button>
            </a>
          </div>
        </div>
        <div className="hidden md:block ">
          <img src={MacBook} alt="MacBook" className="" />
        </div>
      </div>
      <div className="hidden md:flex justify-center  items-center ">
        <div className="flex bg-slate-50 shadowFunctions  absolute  py-8 px-16 rounded-full">
          <div className="borderRight flex flex-col pr-10">
            <img className="ml-12" src={Ping} alt="" />
            <p className="font-bold ">Mapa ao vivo</p>
          </div>
          <div className="borderRight px-10">
            <img className="ml-16" src={Custo} alt="" />
            <p className="font-bold">Controle de custos</p>
          </div>
          <div className="borderRight px-10">
            <img className="ml-8" src={White} alt="" />
            <p className="font-bold">Whitelabel</p>
          </div>
          <div className="pl-10">
            <img className="ml-12" src={Ranking} alt="" />
            <p className="font-bold">Ranking motorista</p>
          </div>
        </div>
      </div>

      <div className=" h-full   md:h-screen w-full gradientBg  flex flex-col justify-center items-center">
        <div className="text-center mt-16 text-white md:mt-28">
          <h1 className="text-2xl w-full tracking-tighter font-bold md:text-5xl ">
            SUA SOLUÇÃO COMPLETA
          </h1>
          <div className="w-full flex justify-center text-center">
            <p className="hidden md:block md:text-center md:text-2xl md:mt-12 md:w-10/12">
              Nosso software de rastreamento é mais do que apenas uma ferramenta
              tecnológica para sua empresa, é um modelo de negócio pronto e
              validado. Com nosso software completo e uma equipe dedicada, você
              pode se concentrar nas vendas, enquanto cuidamos da operação.
            </p>
            <p className="text-left text-sm mt-4 mx-5 md:hidden">
              Nosso software de rastreamento é mais do que apenas uma ferramenta
              tecnológica para sua empresa, é um modelo de negócio validado. Com
              ele você pode focar nas vendas, enquanto cuidamos da operação.
            </p>
          </div>
        </div>
        <div className="mt-4 w-full md:mt-20">
          <img className="w-full" src={DeskTopDash} alt="" />
        </div>
      </div>

      <div className="flex flex-col justify-center mt-14">
        <div className="w-full flex justify-center items-center">
          <img src={NossoAplicativo} alt="" className=" w-full md:w-8/12" />
        </div>
        <div className="mb-4 md:mb-16 flex justify-center">
          <a
            target="_blank"
            href="https://api.whatsapp.com/send?phone=5548991040129&text=Ol%C3%A1,%20vim%20atrav%C3%A9s%20do%20site.%20Quero%20meu%20app%20personalizado.%20"
          >
            <button className="mt-4 py-2 px-4 text-sm md:mt-10 rounded-full md:py-4 md:px-8 buttonAppPersonalizado font-bold hover:scale-110  duration-500">
              Quero meu App Personalizado
            </button>
          </a>
        </div>
      </div>
      <div className="gradientBgTec  flex flex-col  md:h-screen md:flex md:flex-row items-center justify-center md:gap-16">
        <div>
          <h1 className="text-white font-bold text-2xl block mt-5 md:hidden ">
            Nossas Tecnologias
          </h1>
        </div>
        <div className=" w-full flex items-center justify-center md:w-5/12 ">
          <img className="w-8/12 m-5  md:w-full" src={Mandala} alt="" />
        </div>
        <div className="hidden md:flex flex-col">
          <h1 className="text-5xl font-bold text-white mb-16 tracking-tighter">
            NOSSAS TECNOLOGIAS
          </h1>
          <div className="grid grid-cols-2 gap-5  text-white ">
            <div className="border rounded-full py-1 flex justify-center hover:scale-110  duration-300">
              <p className=" font-semibold">Segurança de dados</p>
            </div>
            <div className="border rounded-full  flex justify-center py-1 hover:scale-110  duration-300">
              <p className=" font-semibold">Infraestrutura ilimitada</p>
            </div>
            <div className="border rounded-full flex justify-center w-5/6 py-1 hover:scale-110  duration-300">
              <p className=" font-semibold">Apis's completas</p>
            </div>
            <div className="border rounded-full flex justify-center w-5/6 -ml-10 py-1 hover:scale-110  duration-300">
              <p className="font-semibold">Alta escalabilidade</p>
            </div>
            <div className="border rounded-full flex justify-center py-1 hover:scale-110  duration-300">
              <p className=" font-semibold">Plataforma 100% web</p>
            </div>
            <div className="flex justify-start py-1 hover:scale-110  duration-300">
              <p className="font-semibold">e muito mais...</p>
            </div>
          </div>
        </div>
      </div>
      <div id="about-us">
        <h1 className="text-2xl mt-5 text-center md:mt-16 md:text-5xl font-bold  text-QuemSomos">
          QUEM SOMOS?
        </h1>
        <div className="  flex flex-col my-8 md:flex md:flex-row justify-center md:gap-16 items-center md:my-16 ">
          <div className=" mb-5 w-10/12  md:w-4/12 md:mb-0">
            <img src={Equipe} alt="w-full" />
          </div>
          <div className="w-full flex flex-col items-center justify-center text-center md:w-5/12 md:flex md:text-left md:items-start">
            <h1 className="text-xl md:text-4xl font-bold text-gradientSelsyn mb-2">
              SELSYN TECNOLOGIA
            </h1>
            <div className="bordaAzul mb-5 h-2 w-20 rounded-full md:mb-10"></div>
            <div>
              <p className="text-sm text-left mx-5 md:mx-0 font-semibold md:text-xl">
                <LinhaParceiro /> e estaremos presentes em todas as etapas,
                fornecendo suporte e orientação para garantir o sucesso de seu
                empreendimento. Se você está procurando uma solução de
                rastreamento confiável e eficiente para sua empresa, entre em
                contato conosco hoje mesmo e veja como podemos ajudá-lo a
                alcançar seus objetivos.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container-Ide">
        <h1 className="text-2xl  text-center md:text-5xl font-bold text-QuemSomos">
          NOSSAS IDEOLOGIAS
        </h1>
        <div className="flex justify-end items-center md:gap-10 md:mt-10 text-white">
          <div className=" hidden md:w-2/12 md:block">
            <img className="w-full" src={Slogan} alt="" />
          </div>
          <div
            className={`grid grid-cols-2 py-5 px-4 mt-5 ml-4 gap-2 container-Proprositos bgClaro borderDiv md:flex md:py-8 md:px-8 md:w-3/4 md:gap-6 ${
              isVisible ? "visible" : ""
            }`}
          >
            <div className="px-2 py-2 h-40 bgProp md:h-60 md:py-5 md:w-full md:px-8 rounded-3xl">
              <h2 className="text-sm my-2 font-bold md:text-xl">Propósito</h2>
              <p className=" text-sm md:text-base">
                Prover diferencial nos negócios
              </p>
            </div>
            <div className="px-2 py-2 h-40 bgMiss md:h-60 md:py-5 md:w-full md:px-8 rounded-3xl">
              <h2 className="text-sm my-2 font-bold md:text-xl">Missão</h2>
              <p className="text-sm md:text-base">
                Oferecer soluções, garantindo a satisfação e confiança de nossos
                clientes.
              </p>
            </div>
            <div className="px-2 py-2 h-40 bgVis md:h-60 md:py-5 md:w-full md:px-8 rounded-3xl">
              <h2 className="text-sm my-2 font-bold md:text-xl">Visão</h2>
              <p className="text-sm md:text-base">
                Ser referência no mercado de Telemetria e Rastreamento.
              </p>
            </div>
            <div className="px-2 py-2 h-40 bgVal md:h-60 md:py-5 md:w-full md:px-8 rounded-3xl">
              <h2 className="text-sm my-2 font-bold md:text-xl">Valores</h2>
              <p className="text-sm md:text-base">
                Confiança, inovação e Transparência
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-10 md:mt-20 mb-20 items-center">
        <h1 className="text-2xl mb-10 text-gradientSelsyn md:text-5xl font-bold flex justify-center">
          Feedbacks
        </h1>
        <div
          className="feedback-container overflow-hidden pl-12"
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseLeave}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          <div className="feedbacks flex gap-20">
            <img className="w-10/12" src={sulvias} alt="" />
            <img className="w-10/12" src={Look} alt="" />
            <img className="w-10/12" src={Vieira} alt="" />
            <img className="mb-4 w-10/12" src={autoRastro} alt="" />
            <img className="w-10/12" src={Google5} alt="" />
            <img className="w-10/12" src={Google6} alt="" />
          </div>
        </div>
      </div>
    </>
  );
}
