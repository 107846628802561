import React, { useEffect } from "react";
import Map from "../assets/Map.svg";
import Passos from "../assets/PassoApasso.svg";
import US43 from "../assets/US$ 43,7 BI.svg";
import US90 from "../assets/Noventa.svg";
import Car from "../assets/mentoriacar.svg";
import LinhaAnimacao from "../assets/linhaAnimacao.svg";
import CarroAnimacao from "../assets/carroAnimacao.svg";
import "./Mentoria.css";

export default function Mentoria() {
  useEffect(() => {
    const carElement = document.getElementById("carroAnimacao");
    carElement.classList.add("car-animation");
  }, []);

  return (
    <>
      <div className="z-0 relative flex items-center justify-center md:mt-20 mx-0 md:mx-20 ">
        <div className="flex flex-col justify-center items-center md:items-start md:p-6">
          <h3 className="font-semibold text-sm md:text-2xl mb-3">
            Como montar e porque montar sua
          </h3>
          <h1 className="font-bold text-center mx-2 md:mx-0 text-3xl md:text-start md:text-5xl text-gradient">
            CENTRAL DE RASTREAMENTO E MONITORAMENTO
          </h1>
          <div className="z-0 w-3/12 flex">
            <img
              className="z-0 relative"
              src={LinhaAnimacao}
              alt="Linha de Animação"
            />
            <img
              id="carroAnimacao"
              className="z-10 -ml-3 md:-ml-10"
              src={CarroAnimacao}
              alt="Carro de Animação"
            />
          </div>
        </div>
        <div className="hidden md:block w-6/12">
          <img src={Map} alt="Mapa" className="w-full" />
        </div>
      </div>
      <div className=" w-full mt-5 md:mt-10 flex items-center justify-center md:mb-12">
        <img src={Passos} alt="Passos" className="w-11/12 md:w-8/12" />
      </div>
      <div>
        <h1 className="pt-16 font-bold text-2xl md:text-5xl text-gradient text-center mb-5 md:mb-16">
          PORQUÊ MONTAR?
        </h1>
        <div className="w-full flex flex-col  md:flex-row md:flex items-center justify-center md:gap-10">
          <img src={US43} alt="US 43,7 BI" className="w-10/12 md:w-4/12" />
          <img src={US90} alt="US 90" className="w-10/12 md:w-4/12" />
        </div>
        <div className="flex items-center justify-center md:gap-16">
          <div className="w-40 md:w-52">
            <img className="w-full" src={Car} alt="Carro" />
          </div>
          <div className="w-6/12 md:ml-6">
            <h1 className="titlePlataforma font-bold text-xl md:text-2xl mb-4">
              20% DOS VEÍCULOS
            </h1>
            <p className="font-semibold">
              Estima-se que aproximadamente&nbsp;
              <span className="font-bold titlePlataforma">
                apenas 20% dos veículos no Brasil
              </span>
              &nbsp; são equipados com algum tipo de dispositivo de rastreamento
              ou monitoramento.
            </p>
          </div>
        </div>
        <div className="flex justify-center mt-6 mb-20">
          <a
            href="https://api.whatsapp.com/send?phone=5548991040129&text=Ol%C3%A1,%20vim%20atrav%C3%A9s%20do%20site.%20Quero%20montar%20minha%20central%20e%20conhecer%20mais%20a%20plataforma."
            target="blank"
            rel="noopener noreferrer"
          >
            <button className="font-bold gradientButton px-8 py-4 rounded-full hover:scale-110  duration-500">
              Quero montar minha central
            </button>
          </a>
        </div>
      </div>
    </>
  );
}
