import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Select({ title, item, item2, link, link2 }) {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <MenuButton className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 font-bold text-black ring-inset ring-gray-300 text-3x1">
          {title}
          <ChevronDownIcon
            className="-mr-1 h-5 w-5 text-blue-900"
            aria-hidden="true"
          />
        </MenuButton>
      </div>

      <Transition
        enter="transition-none"
        enterFrom="transform opacity-100 scale-100"
        enterTo="transform opacity-100 scale-100"
        leave="transition-none"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-100 scale-100"
      >
        <MenuItems className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <Link to={link}>
            <div className="py-1">
              <MenuItem>
                {({ active }) => (
                  <a
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm"
                    )}
                  >
                    {item}
                  </a>
                )}
              </MenuItem>
            </div>
          </Link>
          {item2 && (
            <Link to={link2}>
              <div className="py-1">
                <MenuItem>
                  {({ active }) => (
                    <a
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 text-sm"
                      )}
                    >
                      {item2}
                    </a>
                  )}
                </MenuItem>
              </div>
            </Link>
          )}
        </MenuItems>
      </Transition>
    </Menu>
  );
}

Select.defaultProps = {
  item2: null,
};
